import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Wrapper',
        redirect: { name: "Home" },
        component: () => import('./views/Wrapper.vue'),
        // meta: {
        //     title: 'Cyber Theft Watch | Digital Asset Copyright Enforcement',
        //     charset: 'utf-8',
        //     metaTags: [
        //         {
        //             name: 'description',
        //             content: 'Rapid Copyright Enforcement & Brand Protection with AI-Powered Takedowns. Defend Your IP Online and book a free trial today!',
        //         },
        //         {
        //             property: 'og:description',
        //             content: 'Rapid Copyright Enforcement & Brand Protection with AI-Powered Takedowns. Defend Your IP Online and book a free trial today!',
        //         },
        //     ],
        // },
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('./views/menus/Home.vue'),
            },
            {
                path: '/piracy',
                name: 'Piracy',
                component: () => import('./views/menus/PiracySummary.vue'),
            },
            {
                path: '/contact',
                name: 'Contact',
                component: () => import('./views/menus/Contact.vue'),
            },
            {
                path: '/blog',
                name: 'Blog',
                component: () => import('./views/menus/Blog.vue'),
            },
            {
                path: '/article/:title',
                name: 'Article',
                component: () => import('./views/menus/Article.vue'),
            }
        ]
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('./views/legal/PrivacyPolicy.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

// This callback runs before every route change, including on page load.
// router.beforeEach((to, from, next) => {
//     // This goes through the matched routes from last to first, finding the closest route with a title.
//     // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
//     // `/nested`'s will be chosen.
//     const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
//
//     // Find the nearest route element with meta tags.
//     const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//
//     const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//
//     // If a route with a title was found, set the document (page) title to that value.
//     if(nearestWithTitle) {
//         document.title = nearestWithTitle.meta.title;
//     } else if(previousNearestWithMeta) {
//         document.title = previousNearestWithMeta.meta.title;
//     }
//
//     // Remove any stale meta tags from the document using the key attribute we set below.
//     Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
//
//     // Skip rendering meta tags if there are none.
//     if(!nearestWithMeta) return next();
//
//     // Turn the meta tag definitions into actual elements in the head.
//     nearestWithMeta.meta.metaTags.map(tagDef => {
//         const tag = document.createElement('meta');
//
//         Object.keys(tagDef).forEach(key => {
//             tag.setAttribute(key, tagDef[key]);
//         });
//
//         // We use this to track which meta tags we create so we don't interfere with other ones.
//         tag.setAttribute('data-vue-router-controlled', '');
//
//         return tag;
//     })
//         // Add the meta tags to the document head.
//         .forEach(tag => document.head.appendChild(tag));
//
//     next();
// });

export default router;

<template>
    <router-view/>
</template>

<script>
import { useHead } from '@unhead/vue'

export default {
    name: 'App',

    mounted() {
        useHead({
            title: 'Cyber Theft Watch | Digital Asset Copyright Enforcement',
            meta: [
                { name: 'description', content: 'Rapid Copyright Enforcement & Brand Protection with AI-Powered Takedowns. Defend Your IP Online and book a free trial today!' },
                { name: 'og:description', content: 'Rapid Copyright Enforcement & Brand Protection with AI-Powered Takedowns. Defend Your IP Online and book a free trial today!' },
                { name: 'keywords', content: 'Cyber Theft Watch, Digital Asset Copyright Enforcement, Brand Protection, AI-Powered Takedowns, Defend Your IP Online' },
                { name: 'og:title', content: 'Cyber Theft Watch | Digital Asset Copyright Enforcement' }
            ]
        })
    },
}
</script>

<style lang="scss">
    @import './assets/styles/layout.scss';
    .grecaptcha-badge {
        visibility: hidden;
    }
</style>
